@mixin jplayer-components-product-table-price {
    @include lora-components-product-table-price();

    .c-product-table__price-sale {
        flex-direction: column;
        line-height: rem-calc(20);
    }

    .c-cart-summary-table {
        .c-cart-summary-table__cell.m-label {
            .c-icon.m-after-text {
                display: none;
            }
        }
    }
}

@mixin jplayer-components-product-coupon-code {
    .c-cart-coupon__list {//stylelint-disable
        button.c-link,
        button.c-link:active {
            font-weight: normal;
        }
    }
}

@import "@root/05-components/product/product-table-price";