// Layout
$category-cover-body-padding: rem-calc(15) 20% !default; // !default added to override on local level
$category-cover-body-padding--large: rem-calc(15) 10% !default; // !default added to override on local level
$category-cover-body-width--large: 51% !default; // !default added to override on local level
$category-cover-body-margin--large: rem-calc(0 30);

// Title
$category-cover-title-font: 500 #{rem-calc(32)} / 1 $font-secondary !default; // added to override on local level
$category-cover-title-font--large: 500 #{rem-calc(44)} / 1.1 $font-secondary;
$category-cover-title-color: color(text);
$category-cover-title-color--large: $category-cover-title-color;
$category-cover-title-margin: rem-calc(0 0 10) !default; // !default added to override on local level
$category-cover-title-margin--large: 0 -30% rem-calc(15);
// Description
$category-cover-description-font: #{rem-calc(14)} / 1.5 $font-primary !default; // !default added to override on local level
$category-cover-description-font--large: #{rem-calc(16)} / 1.5 $font-primary;
$category-cover-description-color: color(text);
$category-cover-description-color--large: $category-cover-description-color;

@import '@lora/05-components/category-cover';