$video-library-item-icon-color: color(text-secondary);
$video-library-playlist-margin: rem-calc(25 0 0);
$video-library-playlist-margin--large: rem-calc(0 0 0 30);
$video-library-playlist-padding: rem-calc(20 0 0);
$video-library-playlist-border-top-color: 2px solid color(border);
$video-library-playlist-title-font: normal #{rem-calc(16)} / 1.5 $font-secondary;
$video-library-playlist-title-text-transform: none;
$video-library-playlist-title-margin: rem-calc(0 0 10);
$video-library-item-border--medium-down: 1px solid color(secondary);
$video-library-item-border-radius--medium-down: rem-calc(2);
$video-library-item-active-border: 1px solid color(secondary);
$video-library-item-background--medium-down: transparent;
$video-library-item-image-border: none;
$video-library-item-image-margin: rem-calc(0 13 0 0);
$video-library-item-name-line-height: 1.1;
$video-library-item-name-line-height--large: 1.5;
$video-library-item-name-font-size: rem-calc(14);
$video-library-item-name-font-size--large: rem-calc(16);
$video-library-item-name-font-weight--large: bold;
$video-library-item-image-margin--large: rem-calc(0 30 0 0);
$video-library-item-description-color--large: color(dark);

$video-library-list-padding: rem-calc(0 0 15);
$video-library-item-icon-height: rem-calc(25);
$video-library-item-icon-height--large: rem-calc(32);
$video-library-item-icon-width: rem-calc(25);
$video-library-item-icon-width--large: rem-calc(32);

@import '@lora/05-components/video-library';