$product-sticky-bar-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);

$product-sticky-bar-title-font-family: $font-secondary;
$product-sticky-bar-title-font-size: rem-calc(16);

$product-sticky-bar-description-font-size: rem-calc(14);
$product-sticky-bar-description-font-family: $font-secondary;
$product-sticky-bar-description-font-weight: bold;
$product-sticky-bar-description-font-style: italic;

$product-sticky-bar-item-image-border: none;
$product-sticky-bar-item-description-max-width: none;
$product-sticky-bar-item-variation-flex: 0 0 auto;
$product-sticky-bar-item-variation-section-flex: 0 1 auto;
$product-sticky-bar-item-actions-width--large: 25%;
$product-sticky-bar-item-variation-margin: 0;
$product-sticky-bar-item-wishlist-display: none;
$product-sticky-bar-price-margin: 0 2% 0 auto;
$product-sticky-bar-add-bag-width: rem-calc(170);

@import '@lora/05-components/product/product-sticky-bar';