$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Amiri', serif;
$font-tertiary: 'Roboto Condensed', sans-serif;

$font-list: (
    ('Roboto', 'Roboto-Regular', normal, normal),
    ('Amiri', 'AmiriRegular', normal, normal),
    ('Amiri', 'AmiriBold', bold, normal),
    ('Roboto Condensed', 'RobotoCondensed-Bold', bold, normal)
);

@import '@lora/font-settings';