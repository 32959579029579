$search-sorting-field-font-family: $font-tertiary;
$search-sorting-field-font--medium-down: bold #{rem-calc(12)} / 1 $font-primary;
$search-sorting-field-font-weight: bold;
$search-sorting-label-font--medium-down: #{rem-calc(12)} / 1.3 $font-tertiary;
$search-sorting-field-height--medium-down: $global-input-primary-height;
$search-sorting-field-hover-font-weight--medium-down: bold;
$search-sorting-field-padding-left--medium-down: rem-calc(20);
$search-sorting-field-text-transform: uppercase;
$search-sorting-field-text-transform--medium-down: inherit;

@import '@lora/05-components/search/search-sorting';