// Kiehls variables
$product-made-better-item-text-align: center;
$product-made-better-item-margin: rem-calc(0 0 32);
$product-made-better-item-margin--large: rem-calc(0 0 60);
$product-made-better-benefit-text-font: #{rem-calc(12)} / 1.2 $font-primary;
$product-made-better-benefit-text-font--large: bold #{rem-calc(14)} / 1.2 $font-primary;
$product-made-better-benefit-text-margin: rem-calc(24 0 0);
$product-made-better-benefit-text-margin--large: rem-calc(20 0 0);
$product-made-better-cta-text-align: center;

// Made Better section
@mixin master-components-product-made-better {
    .c-made-better__item {
        text-align: $product-made-better-item-text-align;
        margin: $product-made-better-item-margin;

        @include breakpoint(large) {
            margin: $product-made-better-item-margin--large;
        }
    }

    .c-made-better__benefit-text {
        font: $product-made-better-benefit-text-font;
        margin: $product-made-better-benefit-text-margin;

        @include breakpoint(large) {
            font: $product-made-better-benefit-text-font--large;
            margin: $product-made-better-benefit-text-margin--large;
        }
    }

    .c-made-better__cta {
        text-align: $product-made-better-cta-text-align;
    }
}