// How To Steps
$product-how-to-step-color: color(text);

// Custom Counter
$product-how-to-steps-counter-step-display: block;
$product-how-to-steps-counter-step-display--large: flex;
$product-how-to-steps-counter-step-count-display--large: flex;
$product-how-to-steps-counter-step-count-font: #{rem-calc(32)} / #{rem-calc(24)} $font-secondary;
$product-how-to-steps-counter-step-count-margin: rem-calc(10 0);
$product-how-to-steps-counter-step-count-margin--large: rem-calc(0 10 5);
$product-how-to-steps-counter-step-count-width--large: auto;
$product-how-to-media-steps-max-height--large: rem-calc(560);

@import "@lora/100-deprecated/product/product-how-to";
@import "@lora/05-components/product/product-how-to";