
                            $is-app_autoreplenishment-enabled: true;
                            $replenishment-selector-label-font: bold #{rem-calc(14)} / 1.5 $font-primary;
$replenishment-selector-item-margin: 0;
$replenishment-selector-margin: rem-calc(40 0 20);
$replenishment-selector-item-margin: rem-calc(-20 0 0 0);
$replenishment-selector-item-padding: rem-calc(10);
$replenishment-selector-item-padding--large: $replenishment-selector-item-padding;
$replenishment-selector-item-box-shadow: none;
$replenishment-selector-item-border-radius: 0;
$replenishment-selector-content-margin: rem-calc(10 0 0);
$replenishment-selector-item-border: 1px solid color(disabled);

@import "@root/05-components/replenishment-selector";
                        