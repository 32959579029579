$button-social-icon-text-transform: initial;
// Social buttons
$button-social-list: (
    facebook: (
        icon: facebook,
        icon-color: color(light),
        icon-color-hover: color(dark),
        background: null,
        background-hover: null,
        color: null,
        border: null
    ),
    google: (
        icon: google,
        icon-color: color(light),
        icon-color-hover: color(dark),
        background: null,
        background-hover: null,
        color: null,
        border: null
    )
);
/// Default border color for buttons.
/// @type Color
$button-border: 1px solid color(dark);

/// Default border color on hover for buttons.
/// @type Color
$button-hover-border: 1px solid color(dark);

/// Background color on hover for buttons.
/// @type Color
$button-hover-background: color(light);

/// Sizes for buttons.
/// @type Map
$button-sizes: (
    tiny: (
        padding: rem-calc(5 12 4),
        font-size: rem-calc(12),
    ),
    small: (
        padding: rem-calc(8 12),
        font-size: rem-calc(14),
    ),
    large: (
        padding: rem-calc(11 12 10),
        font-size: rem-calc(18),
    ),
);

/// Default font size for buttons.
/// @type Number
$button-font-size: rem-calc(16);

/// Default font family for buttons.
/// @type Keyword
$button-font-family: $font-tertiary;

/// Default font weight for buttons.
/// @type Number
$button-font-weight: bold;

/// Font color on hover for buttons.
/// @type Color
$button-hover-color: color(dark);

/// Primary button color.
/// @type Map
$button-primary: (
    background: color(light),
    background-hover: color(primary),
    border: 1px solid color(primary),
    border-hover: 1px solid color(primary),
    color: color(primary),
    color-hover: color(light)
);

/// Alert button color.
/// @type Map
$button-alert: (
    background: color(light),
    background-hover: color(alert),
    border: 1px solid color(alert),
    color: color(alert),
    color-hover: color(light)
);

/// Alert button color.
/// @type Map
$button-success: (
    background: color(success),
    background-hover: color(success),
    border: 1px solid color(success),
    color: color(text),
    color-hover: color(text)
);

/// Coloring classes. A map of classes to output in your CSS, like `.secondary`, `.success`, and so on.
/// @type Map
$button-palette: (
    primary: $button-primary,
    alert: $button-alert,
    success: $button-success
);
/// Alternative background color for buttons.
/// @type Color
$button-alt-background: color(light);

/// Alternative background color on hover for buttons.
/// @type Color
$button-alt-hover-background: color(dark);

/// Alternative border color for buttons.
/// @type Color
$button-alt-border: 1px solid color(dark);

/// Alternative border color on hover for buttons.
/// @type Color
$button-alt-hover-border: $button-alt-border;

/// Alternative font color for buttons.
/// @type Color
$button-alt-color: $button-alt-hover-background;

/// Alternative font color on hover for buttons.
/// @type Color
$button-alt-hover-color: $button-alt-background;

// Button loading
$button-loading-icon-color: color(light);
$button-loading-icon-hover-color: color(dark);
$button-loading-icon-size: rem-calc(11);

// Button underline
$button-underline-width: auto;
$button-underline-width--large: $button-underline-width;
$button-underline-padding: rem-calc(11 12 10);
$button-underline-padding--large: $button-underline-padding;
$button-underline-color: color(primary);
$button-underline-active-color: color(primary);
$button-underline-color--large: color(organism-background);
$button-underline-active-color--large: color(primary);
$button-underline-border-bottom: 2px solid color(primary);
$button-underline-border-bottom--large: 2px solid color(organism-background);
$button-underline-active-border-bottom: 2px solid color(primary);
$button-underline-active-border-bottom--large: 2px solid color(primary);
$button-underline-font: #{rem-calc(14)} / 1.25 $font-tertiary;
$button-underline-font--large: $button-underline-font;
$button-underline-text-transform: uppercase;

$button-search-height: rem-calc(39);
$button-icon-only-height: rem-calc(39);

@mixin lora-helper-button-underline {
    width: $button-underline-width;
    min-width: 0;
    padding: $button-underline-padding;
    background: none;
    border: 0;
    border-bottom: $button-underline-border-bottom;
    font: $button-underline-font;
    color: $button-underline-color;
    text-transform: $button-underline-text-transform;
    text-decoration: none;

    @include breakpoint(large) {
        width: $button-underline-width--large;
        padding: $button-underline-padding--large;
        font: $button-underline-font--large;
        color: $button-underline-color--large;
        border-bottom: $button-underline-border-bottom--large;
    }

    &.m-active,
    &:hover,
    &:focus {
        background: none;
        color: $button-underline-active-color;
        border: 0;
        border-bottom: $button-underline-active-border-bottom;
        text-decoration: none;

        @include breakpoint(large) {
            color: $button-underline-active-color--large;
            border-bottom: $button-underline-active-border-bottom--large;
        }
    }

    &:focus {
        outline-width: 1px;
    }
}

@mixin master-components-button {
    @include lora-components-button;

    .c-button.m-underline {
        @include lora-helper-button-underline;
    }
}
/// Font Color for a disabled button.
/// @type Color
$button-disabled-color: color(organism-background);

@import '@lora/05-components/button'; //stylelint-disable-line