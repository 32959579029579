$social-share-caption-font: bold #{rem-calc(12)} / 1.4 $font-tertiary;
$social-share-item-margin: 0 rem-calc(15) 0 0;
$social-share-item-margin--large: $social-share-item-margin;
$social-share-font-size: rem-calc(12);
$social-share-font-weight: bold;
$social-share-margin: rem-calc(10 15);
$social-share-large-icon-size: rem-calc(14);
$social-share-large-icon-size--large: $social-share-large-icon-size;
$social-share-large-item-margin: 0 rem-calc(15) 0 0;
$social-share-large-item-margin--large: $social-share-large-item-margin;

@import "@lora/05-components/social-share";