$search-refinement-title-font: bold #{rem-calc(16)}/normal $font-tertiary;
$search-refinement-title-expanded-font: $search-refinement-title-font;
$search-refinement-title-padding--large: rem-calc(20 16);
$search-refinement-title-expanded-padding--large: rem-calc(20 45 20 16);
$search-refinement-title-expanded-padding: rem-calc(15 45 15 15);
$search-refinement-title-background-color: color(global-background);
$search-refinement-border-top--large: 1px solid color(disabled);
$search-refinement-content-background--medium-down: color(light);
$search-refinement-content-wrapper-background-color: color(light);
$search-refinement-content-wrapper-padding: rem-calc(0 0 0 5);
$search-refinements-header-title-color: color(light);
$search-refinements-header-back-icon-color: color(light);
$search-refinement-title-text-text-transform: uppercase;
$search-refinement-title-expanded-border-bottom--medium-down: 2px solid color(border);
$search-refinement-content-wrapper-expanded-padding: rem-calc(16 0 16 5);
$search-refinement-title-expanded-border-left: 2px solid color(secondary-active);
$search-refinement-title-expanded-border-left--large: $search-refinement-title-expanded-border-left;
$search-refinement-content-padding--large: rem-calc(16 0);
// Footer
$search-refinements-footer-box-shadow: none;

@mixin master-components-refinement {
    @include lora-components-refinement;

    .c-refinement {
        &:first-of-type {
            border-top: none;
        }
    }
}

@import "@lora/05-components/search/search-refinement"; //stylelint-disable-line