// Category Name
$search-refinements-category-name-font: bold #{rem-calc(24)} / 1.25 $font-secondary;
$search-refinements-category-name-separator: none;
$search-refinements-category-name-margin: rem-calc(0 0 10);
$search-refinements-category-name-text-transform: capitalize;
// Header
$search-refinements-header-background: color(global-background);
$search-refinements-header-padding--large: rem-calc(16 0 16 16);
$search-refinements-header-back-icon: "cross";

@import "@lora/05-components/search/search-refinements";