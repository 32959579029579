$replenishment-selector-margin: rem-calc(0 0 16) !default;
$replenishment-selector-margin--large: null !default;
// Item
$replenishment-selector-item-padding: rem-calc(16) !default;
$replenishment-selector-item-padding--large: null !default;
$replenishment-selector-item-margin: rem-calc(0 0 5) !default;
$replenishment-selector-item-margin--large: null !default;
$replenishment-selector-item-background: color(light) !default;
$replenishment-selector-item-background--large: null !default;
$replenishment-selector-item-box-shadow: 0 0 2px 0 rgba(color(dark), 0.2) !default;
$replenishment-selector-item-border: null !default;
$replenishment-selector-item-border-radius: rem-calc(8) !default;
// Label
$replenishment-selector-label-font: 700 #{rem-calc(14)} / 1.8 $font-primary !default;
$replenishment-selector-label-font--large: null !default;
$replenishment-selector-label-margin: null !default;
$replenishment-selector-label-margin--large: null !default;
$replenishment-selector-label-padding-right: rem-calc(30) !default;
// Icon
$replenishment-selector-icon-margin: rem-calc(0 0 0 10) !default;
$replenishment-selector-icon-margin--large: null !default;
// Content
$replenishment-selector-content-margin: rem-calc(15 0 0) !default;
$replenishment-selector-content-margin--large: null !default;
$replenishment-selector-content-font: null !default;
$replenishment-selector-content-font--large: null !default;
$replenishment-selector-content-letter-spacing: null !default;
// Price
$replenishment-selector-price-flex: 1 !default;
$replenishment-selector-price-flex-flow: row !default;
$replenishment-selector-price-flex-flow--large: wrap !default;
$replenishment-selector-price-old-margin: 0 !default;
$replenishment-selector-price-font: null !default;
$replenishment-selector-price-old-font: null !default;

@mixin lora-components-replenishment-selector {
    .c-replenishment-selector {
        margin: $replenishment-selector-margin;

        @include breakpoint(large) {
            margin: $replenishment-selector-margin--large;
        }
    }

    .c-replenishment-selector__item {
        padding: $replenishment-selector-item-padding;
        margin: $replenishment-selector-item-margin;
        background: $replenishment-selector-item-background;
        box-shadow: $replenishment-selector-item-box-shadow;
        border: $replenishment-selector-item-border;
        border-radius: $replenishment-selector-item-border-radius;

        @include breakpoint(large) {
            padding: $replenishment-selector-item-padding--large;
            margin: $replenishment-selector-item-margin--large;
            background: $replenishment-selector-item-background--large;
        }
    }

    .c-replenishment-selector__label {
        position: relative;
        display: inline-block;
        padding-right: $replenishment-selector-label-padding-right;
    }

    .c-replenishment-selector__label-text {
        font: $replenishment-selector-label-font;
        margin: $replenishment-selector-label-margin;
        vertical-align: middle;

        @include breakpoint(large) {
            font: $replenishment-selector-label-font--large;
            margin: $replenishment-selector-label-margin--large;
        }
    }

    .c-replenishment-selector__icon {
        margin: $replenishment-selector-icon-margin;
        position: absolute;
        right: 0;
        top: 0;

        @include breakpoint(large) {
            margin: $replenishment-selector-icon-margin--large;
        }
    }

    .c-replenishment-selector__content {
        margin: $replenishment-selector-content-margin;
        font: $replenishment-selector-content-font;
        letter-spacing: $replenishment-selector-content-letter-spacing;

        @include breakpoint(large) {
            margin: $replenishment-selector-content-margin--large;
            font: $replenishment-selector-content-font--large;
        }

        & > :last-child {
            margin-bottom: 0;
        }
    }

    .c-replenishment-selector__price {
        flex: $replenishment-selector-price-flex;
        flex-flow: $replenishment-selector-price-flex-flow;
        font: $replenishment-selector-price-font;

        @include breakpoint(large) {
            flex-flow: $replenishment-selector-price-flex-flow--large;
        }

        .c-product-price__value.m-old {
            margin: $replenishment-selector-price-old-margin;
            font: $replenishment-selector-price-old-font;
        }
    }
}
