$product-review-title-font: normal #{rem-calc(12)} / 1 $font-primary;
$product-review-title-font--large: normal #{rem-calc(12)} / 1 $font-primary;

$product-review-content-font: normal #{rem-calc(12)} / 1 $font-primary;
$product-review-content-font--large: normal #{rem-calc(12)} / 1 $font-primary;

$product-review-content-p-line-height: 2;

$product-review-action-title-font: bold #{rem-calc(12)} / 2 $font-tertiary;
$product-review-action-title-text-transform: uppercase;
$product-review-action-title-color: color(dark);
$product-review-action-title-margin--large: 0;
$product-review-action-buttons-margin--large: rem-calc(0) 0 rem-calc(15);
$product-review-action-useful-color: color(dark);
$product-review-action-unuseful-color: color(dark);

$product-review-padding: 0;
$product-review-margin: 0;
$product-review-border-bottom: 2px solid color(border);
// Toggler
$product-review-toggler-text-transform: none;
$product-review-toggler-icon-margin-top: -(rem-calc(4));
$product-review-toggler-border-bottom: 1px solid color(border);

$product-review-userstats-border-bottom: none;
$product-review-product-info-margin: rem-calc(0 0 35 0);

$product-review-userstats-border-bottom--large: rem-calc(2) solid color(border);
$product-review-padding--large: rem-calc(20 0);
$product-reviews-table-label-font: bold #{rem-calc(12)} / 1 $font-primary;
$product-reviews-table-value-font: bold #{rem-calc(14)} / 1 $font-primary;

@import '@lora/05-components/product/product-review';