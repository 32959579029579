$product-detail-image-badge-top: rem-calc(7);
$product-detail-image-badge-large-top: rem-calc(16);
$product-detail-image-badge-left: rem-calc(7);
$product-detail-image-badge-left--large: rem-calc(50);
$product-detail-image-badge-width--large: 100%;

$product-detail-image-image-carousel-pagination--large: none;

$product-detail-image-zoom-right: rem-calc(7);
$product-detail-image-zoom-right--large: rem-calc(16);
$product-detail-image-zoom-top: auto;
$product-detail-image-zoom-bottom: rem-calc(10);

// Mosaic view
$product-detail-image-mosaic-height: auto;
$product-detail-image-mosaic-padding: 0;
$product-detail-image-mosaic-overflow: visible;

// Horizontal carousel
$product-detail-image-alternatives-horizontal-max-width: none !default; //!default added to override on local level
$product-detail-image-alternatives-horizontal-max-width--large: rem-calc(503);
$product-detail-image-alternatives-horizontal-padding: rem-calc(0 15) !default; //!default added to override on local level

@import '@lora/05-components/product/product-detail-image';