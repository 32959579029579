@import '@lora/03-base/colors';
$palette-custom: (
    dark: #000, // kiehl's
);
$dark-mode-colors: (
    background:     #000,
    primary:        #000,
    primary-active: #373737,
    text:           #fff,
    border:         #fff,
);
$palette: map-extend($palette-default, $palette-custom);
$semantic-colors-custom: (
    primary:             color(dark),
    primary-active:      #373737,
    secondary:           #ccc,
    secondary-active:    #00a3ff,
    active-background:   #e4f2fa,
    text:                color(dark),
    text-secondary:      #666,
    text-tertiary:       #ffd9b7,
    success:             #03c3b2,
    warning:             #ffaa35,
    alert:               #bc403a,
    info:                #00a3ff,
    disabled:            #d8d8d8,
    element-background:  #f8f8f8,
    global-background:   #f8f8f8,
    organism-background: #595959,
    border:              #e9e9e9,
    overlay:             rgba(color(dark), 0.65),
    cod-grey:            #2c2e2f,
    primary-background:  #f7fcff,
    info-secondary:      #edf7ff,
    success-secondary:   #e6f9f7,
    warning-secondary:   #fff7eb,
    error-secondary:     #f8eceb,
    loyalty:             #6f6bf2,
);
$semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom);
