$scrollable-arrow-theme: (
    light: (
        background: color(light),
        color: color(primary),
        box-shadow: none
    ),
    dark: (
        background: color(dark),
        color: color(light)
    )
);
$scrollable-arrow-sizes: (
    default: (
        arrow-icon-width: rem-calc(20),
        arrow-icon-height: rem-calc(29),
        arrow-icon: chevron-right
    ),
    medium: (
        arrow-width: rem-calc(17),
        arrow-height: rem-calc(20),
        arrow-icon-width: rem-calc(7),
        arrow-icon-height: rem-calc(17),
        arrow-icon: chevron-right
    )
);

$scrollable-arrow-color: color(light);

@import '@lora/05-components/scrollable';