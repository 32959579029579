@import "@root/05-components/form/form";

$form-account-edit-profile-width: rem-calc(450);
$form-account-edit-profile-padding: rem-calc(40);
$form-account-registration: null !default;

.c-account__registration {
    margin: $form-account-registration;

    .c-form__row.m-inlined-auto {
        @include breakpoint(medium up) {
            flex-wrap: nowrap;
        }
    }
}

@mixin jplayer-components-form {
    .l-account-edit-profile-section {
        @include breakpoint(large) {
            .c-form.c-form-profile {
                display: inline-block !important; // stylelint-disable-line declaration-no-important
            }

            .c-form__section {
                float: left;
                width: $form-account-edit-profile-width;
                padding-right: $form-account-edit-profile-padding;
            }
        }

        @include breakpoint(medium down) {
            .c-form-profile .c-field {
                display: block;
            }

            .c-form__section {
                width: 100%;
                padding-bottom: $form-account-edit-profile-padding;
            }

            .c-form.c-form-profile {
                display: block;
                width: 100%;
            }
        }
    }

    @include breakpoint(medium down) {
        .c-cart-gift__action,
        .c-cart-gift__actions {
            margin: 0 !important; // stylelint-disable-line declaration-no-important
        }
    }
}
