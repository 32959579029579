$simple-navigation-background: color(dark);
$simple-navigation-color: color(light);
$simple-navigation-font: bold #{rem-calc(12)} / 1.5 $font-tertiary;
$simple-navigation-item-font-weight: bold;
$simple-navigation-item-letter-spacing: rem-calc(0.6);
$simple-navigation-item-active-color: color(light);
$simple-navigation-subcategories-active-link-color: color(light);
// PLP Specific Styles
$simple-navigation-back-link-background: $simple-navigation-background;
$simple-navigation-back-link-border-right: 1px solid color(text-secondary);
$simple-navigation-back-link-color: $simple-navigation-color;
//PDP Sections Styles
$simple-navigation-anchors-link-active-color: color(secondary-active);
$simple-navigation-anchors-link-color: inherit;
$simple-navigation-anchors-link-active-border-bottom: rem-calc(3) solid color(secondary-active);

@import '@lora/05-components/simple-navigation';