$back-to-parent-text-transform: uppercase;
$back-to-parent-color: color(text);
$back-to-parent-font: #{rem-calc(12)} / 1.5 $font-tertiary;
$back-to-parent-secondary-icon-vertical-align: top;
$back-to-parent-icon-height: rem-calc(11);
$back-to-parent-icon-width: rem-calc(7);
$back-to-parent-icon-margin: rem-calc(0 5 0 0);

$back-to-parent-secondary-font: bold #{rem-calc(12)} / 1 $font-tertiary;
$back-to-parent-secondary-font--large: $back-to-parent-secondary-font;

@import '@lora/05-components/back-to-parent';