$product-main-padding: rem-calc(0 0 15);
$product-main-padding--large: rem-calc(40 0);
$product-main-image-position: relative;
$product-main-border: none;
$product-main-aside-padding: 0;
$product-main-aside-background-color--large: transparent;
$product-main-info-text-align: center;
$product-main-info-text-align--large: left;
$product-main-inner-grid-template-rows--large: auto 1fr;

// Product name
$product-main-name-font: bold #{rem-calc(24)} / 1.25 $font-secondary;
$product-main-name-font--large: bold #{rem-calc(32)} / 1 $font-secondary;
$product-main-name-margin: rem-calc(0 0 5);
$product-main-name-margin--large: rem-calc(0 0 15);

// Product Short Description
$product-main-short-description-font: italic normal #{rem-calc(14)} / 1.4 $font-secondary;
$product-main-short-description-color: color(text);
$product-main-short-description-margin: rem-calc(0 0 16);
$product-main-short-description-display: block;

// Product Subtitle
$product-main-subtitle-font--large: #{rem-calc(14)} / 1.25 $font-primary;
$product-main-subtitle-margin: rem-calc(0 0 20);
$product-main-subtitle-margin--large: rem-calc(0 0 20);

// Product Rating
$product-main-review-font: bold #{rem-calc(12)} / 1.5 $font-primary;
$product-main-rating-rating-margin: rem-calc(0 8 0 0);
$product-main-rating-rating-margin--large: rem-calc(0 10 0 0);
$product-main-rating-display: inline-flex;
$product-main-rating-display--large: flex;
$product-main-rating-rating-font-size: rem-calc(14);
$product-main-rating-rating-font-size--large: rem-calc(18);
$product-main-rating-rating-icon-margin: rem-calc(1);
$product-main-rating-margin: rem-calc(10 0 18);

// Product Price
$product-main-price-font: bold #{rem-calc(18)} / 1.4 $font-tertiary;
$product-main-price-font--large: bold #{rem-calc(20)} / 1.4 $font-tertiary;
$product-main-price-margin: rem-calc(0 5 15 0);

// Product variations dropdown
$product-main-variations-dropdown-width: 100%;

// Recommendations Title
$product-main-recommendation-title-font: normal #{rem-calc(24)} / 1.5 $font-secondary;
$product-main-recommendation-title-font--large: normal #{rem-calc(32)} / 1.25 $font-secondary;
$product-main-recommendation-title-text-transform: none;
$product-main-recommendation-title-margin: rem-calc(0 0 25);
$product-main-recommendation-title-margin--large: rem-calc(0 0 45);

// Recommendations Message
$product-main-recommendation-message-font: normal #{rem-calc(14)} / 1.25 $font-primary;
$product-main-recommendation-message-color: color(text);
$product-main-recommendation-message-margin: rem-calc(0 0 17);
$product-main-recommendation-message-margin--large: rem-calc(0 0 17);

// Product Quantity Selector
$product-main-quantity-margin--large: rem-calc(0 15 0 0);

// Product Add to Bag
$product-main-actions-margin: rem-calc(0 0 5);

// Product icons
$product-main-icons-top: rem-calc(7);
$product-main-icons-top--large: rem-calc(16);
$product-main-icons-right: rem-calc(35);
$product-main-icons-right--large: rem-calc(41);
$product-main-review-highlight-total-font: #{rem-calc(14)} / 1.5 $font-primary;
$product-main-review-highlight-total-color: color(text-secondary);
$product-main-review-highlight-icon-before-display: none;
$product-main-review-count-font: bold #{rem-calc(12)} / 1.5 $font-primary;
$product-main-review-count-color: color(text);
$product-main-review-highlight-icon-image-width: rem-calc(15);
$product-main-review-highlight-icon-before-content: "\2014";

@import '@lora/05-components/product/product-main';