$accordion-icon-width: rem-calc(20);
$accordion-title-font: bold #{rem-calc(16)} / 1.4 $font-tertiary;
$accordion-title-secondary-text-transform: uppercase;
$accordion-title-secondary-padding: rem-calc(15 50 15 20);
$accordion-content-inner-padding-collapsed: rem-calc(20 15);
$accordion-content-inner-padding-collapsed--large: rem-calc(20);
$accordion-item-border-bottom-collapsed: 2px solid color(border);
$accordion-title-tertiary-font: #{rem-calc(20)} / 1 $font-secondary;
$accordion-title-tertiary-font--large: #{rem-calc(32)} / 1 $font-secondary;
$accordion-title-tertiary-text-transform: none;
$accordion-title-tertiary-padding: 0.8em rem-calc(40) 0.8em rem-calc(10);
$accordion-title-tertiary-padding--large: 0.8em rem-calc(80) 0.8em rem-calc(30);
$accordion-icon-tertiary-font-size: rem-calc(16px);
$accordion-icon-tertiary-font-size--large: rem-calc(21px);
$accordion-icon-tertiary-margin: rem-calc(0 10);
$accordion-icon-tertiary-margin--large: rem-calc(0 20);
$accordion-icon-tertiary-font-weight: bold;
$accordion-title-tertiary-expanded-background-color: color(element-background);
$accordion-content-tertiary-collapsed-padding: rem-calc(0 35 0 10);
$accordion-content-tertiary-collapsed-padding--large: rem-calc(0 30);
$accordion-content-tertiary-collapsed-font: #{rem-calc(14)} / 1.5 $font-primary;
$accordion-content-tertiary-collapsed-font--large: $accordion-content-tertiary-collapsed-font;
$accordion-content-tertiary-expanded-padding: rem-calc(15 35 15 10);
$accordion-content-tertiary-expanded-padding--large: rem-calc(30 80 30 30);
$accordion-content-font-size: rem-calc(14);
$accordion-content-font-size--large: $accordion-content-font-size;

@mixin lora-helper-accordion-item-tertiary-collapsed {
    &:first-child {
        border-top: $accordion-item-border-bottom-collapsed;
    }
}

@mixin lora-helper-accordion-content-tertiary-collapsed {
    padding: $accordion-content-tertiary-collapsed-padding;
    font: $accordion-content-tertiary-collapsed-font;

    @include breakpoint(large) {
        padding: $accordion-content-tertiary-collapsed-padding--large;
        font: $accordion-content-tertiary-collapsed-font--large;
    }
}

@mixin lora-helper-accordion-content-tertiary-expanded {
    padding: $accordion-content-tertiary-expanded-padding;
    box-sizing: content-box;

    @include breakpoint(large) {
        padding: $accordion-content-tertiary-expanded-padding--large;
        box-sizing: content-box;
    }
}

@mixin lora-helper-accordion-control-tertiary-expanded {
    background-color: $accordion-title-tertiary-expanded-background-color;
    border-bottom: $accordion-item-border-bottom-collapsed;
}

@mixin lora-helper-accordion-control-tertiary-collapsed {
    font: $accordion-title-tertiary-font;
    text-transform: $accordion-title-tertiary-text-transform;
    padding: $accordion-title-tertiary-padding;

    &::after {
        font-size: $accordion-icon-tertiary-font-size;
        font-weight: $accordion-icon-tertiary-font-weight;
        font-family: $font-tertiary;
        margin: $accordion-icon-tertiary-margin;
        margin-top: -($accordion-icon-width/2);
        @include breakpoint(large) {
            font-size: $accordion-icon-tertiary-font-size--large;
            margin: $accordion-icon-tertiary-margin--large;
            margin-top: -($accordion-icon-width/2);
        }
    }

    @include breakpoint(large) {
        font: $accordion-title-tertiary-font--large;
        padding: $accordion-title-tertiary-padding--large;
    }
}
@mixin master-components-accordion {
    @include lora-components-accordion;

    .c-accordion.m-tertiary {
        .c-accordion__item.m-expanded {
            .c-accordion__title {
                @include lora-helper-accordion-control-tertiary-expanded;
            }

            .c-accordion__content {
                @include lora-helper-accordion-content-tertiary-expanded;
            }
        }

        .c-accordion__title {
            @include lora-helper-accordion-control-tertiary-collapsed;

            margin-bottom: 0;
        }

        .c-accordion__content {
            @include lora-helper-accordion-content-tertiary-collapsed;

            & > p:last-child {
                margin: 0; // Remove margin for last item for vertical rhythm
            }
        }

        .c-accordion__item {
            @include lora-helper-accordion-item-tertiary-collapsed;
        }
    }
}

@import '@lora/05-components/accordion'; //stylelint-disable-line