// Layout
$product-main-v2-image-order: 0;
$product-main-v2-info-text-align: center;
$product-main-v2-info-text-align--large: left;
$product-main-v2-padding: rem-calc(30 0 15);
$product-main-v2-padding--large: rem-calc(65 0 40);
$product-main-v2-image-padding: rem-calc(0 0 10);
// Main Image
$product-main-v2-detail-image-padding: rem-calc(0 30);
$product-main-v2-detail-image-padding--large: 0;
// Product Name
$product-main-v2-name-font: bold #{rem-calc(24)} / 1.25 $font-secondary;
$product-main-v2-name-font--large: bold #{rem-calc(35)} / 1 $font-secondary;
$product-main-v2-name-margin: rem-calc(0 0 5);
$product-main-v2-name-margin--large: rem-calc(0 0 15);
// Product Subtitle
$product-main-v2-subtitle-color: color(text);
$product-main-v2-subtitle-color--large: color(text);
$product-main-v2-subtitle-font: #{rem-calc(14)} / 1.5 $font-primary;
$product-main-v2-subtitle-font--large: #{rem-calc(15)} / 1.5 $font-primary;
$product-main-v2-subtitle-margin: rem-calc(0 0 12);
$product-main-v2-subtitle-margin--large: rem-calc(0 0 20);
// Rating
$product-main-v2-rating-display: null;
$product-main-v2-rating-justify-content: null;
$product-main-v2-rating-justify-content--large: flex-start;
// Product Price
$product-main-v2-price-font: $product-main-price-font;
$product-main-v2-price-font--large: $product-main-price-font--large;
$product-main-v2-price-border-bottom: none;
$product-main-v2-price-margin: 0;
$product-main-v2-price-margin--large: 0;
$product-main-v2-price-padding: rem-calc(0 30 0 0);
$product-main-v2-price-padding--large: rem-calc(0 30 0 0);
// Availability
$product-main-v2-availability-label-font: bold #{rem-calc(12)} / 1.25 $font-tertiary;
$product-main-v2-availability-justify-content: flex-start;
$product-main-v2-availability-justify-content--large: flex-end;
// Variation Section
$product-main-v2-variation-section-margin: rem-calc(15 0 25);
$product-main-v2-variation-section-margin--large: rem-calc(20 0 15);
// Delivery date message
$product-main-v2-delivery-date-message-flex-direction: row;
$product-main-v2-delivery-date-message-flex-direction--large: $product-main-v2-delivery-date-message-flex-direction;
$product-main-v2-delivery-date-message-justify-content: flex-start;
$product-main-v2-delivery-date-message-justify-content--large: flex-end;

@import '@lora/05-components/product/product-main-v2';