$product-variations-carousel-link-min-height: rem-calc(32);
$product-variations-carousel-link-background: color(light);
$product-variations-carousel-link-border: 1px solid color(disabled);
$product-variations-carousel-link-font: #{rem-calc(13)} / 1.25 $font-primary;
$product-variations-carousel-link-border-radius: 0;
$product-variations-carousel-link-padding: rem-calc(7 10);
$product-variations-carousel-link-align-items: center;

$product-variations-carousel-link-selected-background: color(primary-background);
$product-variations-carousel-link-selected-border: 1px solid color(secondary-active);
$product-variations-carousel-link-selected-font: #{rem-calc(13)} / 1.25 $font-primary;

$product-variations-carousel-link-disabled-background: none;
$product-variations-carousel-disabled-price-value-color: color(secondary);
$product-variations-carousel-value-font-weight: bold;

$product-variations-carousel-link-disabled-color: color(secondary);
$product-variations-carousel-link-disabled-border: 1px solid color(secondary);
$product-variations-carousel-disabled-price-value-color: color(secondary);

// Badge title
$product-variations-carousel-link-badge-title-bottom: 90%;

@import '@lora/05-components/product/product-variations-carousel';