$carousel-grid-columns: 2;
$carousel-grid-columns--large: 4;

// Arrows
$carousel-arrow-sizes: (
    default: (
        arrow-width: rem-calc(54),
        arrow-height: rem-calc(54),
        arrow-icon-width: rem-calc(25),
        arrow-icon-height: rem-calc(35),
        arrow-icon: chevron-right
    ),
    small: (
        arrow-width: rem-calc(23),
        arrow-height: rem-calc(23),
        arrow-icon-width: rem-calc(9),
        arrow-icon-height: rem-calc(15),
        arrow-icon: chevron-right
    ),
    medium: (
        arrow-width: rem-calc(40),
        arrow-height: rem-calc(40),
        arrow-icon-width: rem-calc(9),
        arrow-icon-height: rem-calc(15),
        arrow-icon: chevron2-right
    )
);

$carousel-arrow-theme: (
    light: (
        background: rgba(color(light), 0.7),
        color: color(primary)
    ),
    dark: (
        background: color(dark),
        color: color(light)
    )
);

$carousel-arrow-border-radius: 50%;
$carousel-arrow-inside-prev-left: rem-calc(5);
$carousel-arrow-inside-next-right: rem-calc(5);

// Pagination
$carousel-pagination-bullet-size: rem-calc(15);
$carousel-pagination-bullet-border: 1px solid color(dark);
$carousel-pagination-bullet-custom-before-background: color(secondary);

// Item Title
$carousel-item-title-color: color(text);
$carousel-item-title-color--large: $carousel-item-title-color;
$carousel-item-title-font: bold #{rem-calc(14)} / 1.4 $font-secondary;
$carousel-item-title-font--large: bold #{rem-calc(24)} / 1.4 $font-secondary;

// Item Description
$carousel-item-description-color: color(text);
$carousel-item-description-color--large: $carousel-item-description-color;
$carousel-item-description-text-transform: none;
$carousel-item-description-text-transform--large: $carousel-item-description-text-transform;

@import '@lora/05-components/carousel';